import React, {useEffect, useRef, useState} from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {calculateETFSavings, formatNumber, setInvalid, validateAndSet, validateCheck} from '../utils/utils';
import Accordion from 'rsuite/Accordion';
import ArrowComponent from './ArrowComponent';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Calculator = () => {
	const [res, setRes] = useState({});
	// const [firstDepositSlider, setFirstDepositSlider] = useState(0);
	const [firstDeposit, setFirstDeposit] = useState('0');
	const [monthlyDeposit, setMonthlyDeposit] = useState('150');
	const [years, setYears] = useState('20');
	const [payout, setPayout] = useState('');
	const [etf, setEtf] = useState('');
	const [interestRate, setInterestRate] = useState('4,00');
	// parameters
	const [chartColors, setChartColors] = useState(['#FCDCB9', '#ef7c00']);
	const [buttonColors, setButtonColors] = useState(['#ffffff', '#ef7c00']);
	const [headingSize, setHeadingSize] = useState('16px');
	const [paragraphSize, setParagraphSize] = useState('16px');
	const [chartFontSize, setChartFontSize] = useState('12px');
	const [fontFamily, setFontFamily] = useState('ClassicGrotesquePro');
	const [fontFamily2, setFontFamily2] = useState('Druk Web');
	// end parameters
	const [tickValues, setTickValues] = useState([]);
	const [chartValues, setChartValues] = useState([]);
	const chartContainerRef = useRef(null);
	const calculatorContainerRef = useRef(null);
  	const [chartWidth, setChartWidth] = useState(0);
  	const [calculatorHeight, setCalculatorHeight] = useState(0);
  	const [quelle, setQuelle] = useState('');
  	const [toggleValue, setToggleValue] = useState('Details anzeigen');

  	// iframe effects
  	useEffect(() => {
        window.parent.postMessage({ type: 'calculator_loaded' }, '*');
    }, []);

	useEffect(() => {
		// Function to parse query parameters from URL
		const getQueryParams = () => {
		  const search = window.location.search;
		  const params = new URLSearchParams(search);
		  const queryParams = {};
		  for (const [key, value] of params.entries()) {
			queryParams[key] = value;
		  }
		  return queryParams;
		};

		// Get query parameters when component mounts
		const params = getQueryParams();

		if (params.chartColor1) {
            setChartColors(prevColors => ["#"+params.chartColor1, prevColors[1]]);
        }
        if (params.chartColor2) {
            setChartColors(prevColors => [prevColors[0], "#"+params.chartColor2]);
        }

        if (params.buttonColor1) {
            setButtonColors(prevColors => ["#"+params.buttonColor1, prevColors[1]]);
        }
        if (params.buttonColor2) {
            setButtonColors(prevColors => [prevColors[0], "#"+params.buttonColor2]);
        }
        if (params.headingSize) {
            // if contains px dont concatenate px
        	if(params.headingSize.includes('px')) {
        		setHeadingSize(params.headingSize);
        	} else {
        		setHeadingSize(params.headingSize + "px");
        	}
        }
        if (params.paragraphSize) {
        	// if contains px dont concatenate px
        	if(params.paragraphSize.includes('px')) {
        		setParagraphSize(params.paragraphSize);
        	} else {
        		setParagraphSize(params.paragraphSize + "px");
        	}
        }
        if (params.chartFontSize) {
        	// if contains px remove
        	if(params.chartFontSize.includes('px')) {
        		setChartFontSize(params.chartFontSize.replace('px',''));
        	} else {
        		setChartFontSize(params.chartFontSize);
        	}
        }
        if (params.fontFamily) {
            setFontFamily(params.fontFamily);
        }
        if (params.fontFamily2) {
            setFontFamily2(params.fontFamily2);
        }

        const screenWidth = window.innerWidth;
		// Define the threshold for mobile view
		const mobileViewThreshold = 768; // Adjust as needed

		if (screenWidth <= mobileViewThreshold) {
			if(res.data) {
				if(res.data.length > 9) {
					const numTicks = res.data ? res.data.length : 0;

					// Generate tick values, ensuring that the array length does not exceed numTicks
					const step = 5; // Adjust the step size as needed
					const maxTick = Math.ceil(numTicks / step) * step; // Round up to the next multiple of the step size
					const newTickValues = Array.from({ length: maxTick / step }, (_, index) => numTicks < (index + 1) * step ? numTicks : (index + 1) * step); // Start from 1 and increase by the step size
					// add 1 to array
					newTickValues.unshift(1);
					setTickValues(newTickValues);
				} else {
					setTickValues(undefined);
				}
				if(res.data.length > 9) {
					const numValues = res.data ? res.data.length : 0;

					// Generate values, ensuring that the array length does not exceed numTicks
					const step = 5; // Adjust the step size as needed
					const maxValue = Math.ceil(numValues / step) * step; // Round up to the next multiple of the step size
					const newValues = Array.from({ length: maxValue / step }, (_, index) => numValues < (index + 1) * step ? numValues : (index + 1) * step); // Start from 1 and increase by the step size
					// add 1 to array
					newValues.unshift(1);

					// Filter res.data to only include items with indexes specified in `indexes`
					const filteredData = res.data.filter(item => newValues.includes(item.year));
					setChartValues(filteredData);
				} else {
					setChartValues(res.data);
				}
			}

		} else {
			setTickValues(undefined);
			setChartValues(res.data);
		}
  	}, [res.data]);

  	useEffect(() => {
  		const lowerFontFamily = fontFamily.toLowerCase();
  		if (!lowerFontFamily.includes('moto') && !lowerFontFamily.includes('classicgrotesquepro')) {
  			// Dynamically create <link> tag to import Google Font
  			const capitalizedFontFamily = fontFamily.replace(/\b\w/g, char => char.toUpperCase());

			const link = document.createElement('link');
			link.href = `https://fonts.googleapis.com/css2?family=${capitalizedFontFamily.replace(' ', '+')}`;
			link.rel = 'stylesheet';
			document.head.appendChild(link);
	  	}
	}, [fontFamily]);

  	useEffect(() => {
		// Function to update chart width
		const updateChartWidth = () => {
		  if (chartContainerRef.current) {
			setChartWidth(chartContainerRef.current.offsetWidth);
		  }
		};

		// Update chart width when the component mounts
		updateChartWidth();

		// Update chart width when the window is resized
		window.addEventListener('resize', updateChartWidth);

		// Cleanup function to remove event listener
		return () => {
		  window.removeEventListener('resize', updateChartWidth);
		};
	}, []);

	// calculator height
	useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                // Detect height changes
                const newHeight = entry.contentRect.height;
                setCalculatorHeight(newHeight);
		        window.parent.postMessage({ type: 'calculator_height', height: newHeight }, "*");
            }
        });

        if (calculatorContainerRef.current) {
            observer.observe(calculatorContainerRef.current);
        }

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []); // Run effect only once when component mounts

	// const handleFirstDepositSlider = (value) => {
	// 	setFirstDeposit(value.toLocaleString('de-DE'));
	// 	setFirstDepositSlider(value);
	// };

	ChartJS.defaults.font.family = fontFamily;

	const chartOptions = {
	  responsive: true,
	  maintainAspectRatio: false,
	  interaction: {
		  mode: 'index'
	  },
	  scales: {
		x: {
		  title: {
		  	display: true,
		  	text: "Jahre",
		  	font: {
				size: chartFontSize,
				weight: 600
			}
		  },
		  stacked: true,
		  grid: {
		  	drawOnChartArea: false,
		  	drawTicks: false
		  },
		  ticks: {
		  	padding: 10,
		  	font: {
				size: chartFontSize,
				weight: 400
			},
			maxRotation: 0,
	        minRotation: 0,
		  },
		  border: {
		  	display: false
		  },
		},
		y: {
		  title: {
		  	display: true,
		  	text: "Gesamtkapital",
		  	font: {
				size: chartFontSize,
				weight: 600
			}
		  },
		  stacked: true,
		  grid: {
		  	drawOnChartArea: false,
		  	drawTicks: true
		  },
		  ticks: {
		  	padding: 10,
		  	maxTicksLimit: 4,
		  	callback: function(value, index, ticks) {
				return formatNumber(value, 0) + ' €';
			},
			font: {
				size: chartFontSize,
				weight: 400
			}
		  },
		  border: {
		  	display: false
		  }
		},
	  },
	  plugins: {
	  	legend: {
		  display: false // Disable legend
		},
		tooltip: {
		  padding: 16,
		  backgroundColor: "rgba(255,255,255,0.9)",
		  titleColor: "#000000",
		  bodyColor: "#000000",
		  footerColor: "#000000",
		  footerSpacing: 0,
		  footerMarginTop: 2,
		  caretSize: 0,
		  bodyFont: {
			  size: paragraphSize,
			  weight: 400
		  },
		  footerFont: {
			  size: paragraphSize,
			  weight: 400
		  },
		  displayColors: false,
		  callbacks: {
		  	title:  function (context) {
				return '';
			},
			label: function(context) {
				let label = context.dataset.label || '';

				if (label) {
					label += ': ';
				}
				if (context.parsed.y !== null) {
					label += formatNumber(context.parsed.y) + " €";
				}
				return label;
			},
			footer: function(context) {
				return "Gesamtvermögen: " + formatNumber(context[1].parsed._stacks.y[2]) + " €";
			}
		  },
		},
	  },
	};

	const chartData = {
	  labels: chartValues?.map(item => item.year),
	  datasets: [
		{
			  label: 'Gesamteinzahlungen',
			  data: chartValues?.map(item => item.gesamteinzahlungen),
			  backgroundColor: chartColors[0],
		},
		{
		  label: 'Wertzuwachs',
		  data: chartValues?.map(item => item.wertzuwachs),
		  backgroundColor: chartColors[1],
		},
		{
		  label: 'Gesamtvermögen',
		  data: chartValues?.map(item => item.end_balance),
		  backgroundColor: chartColors[1],
		  hidden: true
		},
	  ],
	};

  	const handleFirstDeposit = (e) => {
		const newValue = e.target.value;
		const numeric = newValue.replace(/\./g,'');
		if (numeric.length < 1) {
			setInvalid(e.target);
			setFirstDeposit('');
			return;
		}
		if (isNaN(numeric)) return;
		validateAndSet(e.target);
		const monthlyInput = document.getElementById('monthly_deposits');
		validateAndSet(monthlyInput);
		// setFirstDepositSlider(Number(numeric));
		setFirstDeposit(Number(numeric).toLocaleString('de-DE'));
	};

	const handleMonthlyDeposit = (e) => {
		const newValue = e.target.value;
		const numeric = newValue.replace(/\./g,'');
		if (numeric.length < 1) {
			setInvalid(e.target);
			setMonthlyDeposit('');
			return;
		}
		if(isNaN(numeric)) return;
		validateAndSet(e.target);
		const firstDepositInput = document.getElementById('first_deposit');
		validateAndSet(firstDepositInput);
		setMonthlyDeposit(Number(numeric).toLocaleString('de-DE'));
	};

	const handleYears = (e) => {
		const newValue = e.target.value;
		const numeric = newValue.replace(/\./g,'');
		// Check if the input value contains more than 1 character
	    if (numeric.length < 1) {
			setInvalid(e.target);
			setYears('');
			return;
		}
		if (isNaN(numeric)) return;
		validateAndSet(e.target);
		setYears(Number(numeric).toLocaleString('de-DE'));
	}

	const handleInterestRate = (e) => {
		let selectedValue = e.target.value;

		// Remove any non-numeric characters except comma
		selectedValue = selectedValue.replace(/[^\d,.]/g, '');

		// Replace dots with commas
  		selectedValue = selectedValue.replace(/\./g, ',');

		// Remove all commas except the first one
		const commaIndex = selectedValue.indexOf(',');
		if (commaIndex !== -1) {
			const beforeComma = selectedValue.substring(0, commaIndex);
			const afterComma = selectedValue.substring(commaIndex + 1).replace(/,/g, '');
			selectedValue = beforeComma + ',' + afterComma;
		}
		validateAndSet(e.target);
		setInterestRate(selectedValue);
	}

	const handleEtfChange = (e) => {
		const selectedValue = e.target.value;
		setEtf(selectedValue);
		setInterestRate(selectedValue);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const formElement = e.target;
		let isValid = false;
		const inputs = formElement.querySelectorAll('input[type="text"]');
		for (let i = 0; i < inputs.length; i++) {
			isValid = validateCheck(inputs[i]);
			if (!isValid) {
				inputs[i].focus();
			  break; // Break out of the loop if isValid is false
			}
		}

		if (isValid) {
			const res = calculateETFSavings(
				+formElement.first_deposit.value.replace(/\./g,''),
				+formElement.monthly_deposits.value.replace(/\./g,''),
				+formElement.interval.value,
				+formElement.interest_rate.value.replace(',', '.'),
				+formElement.period.value.replace(/\./g,''),
			);
			setRes(res);
		} else {
			setRes({});
		}

		// Get the selected option from the select element
		const selectedOption = formElement.querySelector('select[name="etf"] option:checked');
		// Get the value of the 'data-quelle' attribute from the selected option
		const quelle = selectedOption.getAttribute('data-quelle');
		setQuelle(quelle);
	};

	return (
		<div className="calculator-container"
			 ref={calculatorContainerRef}
			 data-height={calculatorHeight}
			 style={{ fontFamily: fontFamily }}
		>
			<div className="calculator-form_container">
				<form
					className="form"
					onSubmit={handleSubmit}
					noValidate
				>
					<div className="input-group">
						<label htmlFor="first_deposit" style={{ fontSize: headingSize }}>Startkapital</label>
						<div className="inp">
							{/*<Slider*/}
							{/*	progress*/}
							{/*	value={firstDepositSlider}*/}
							{/*	onChange={ value => { handleFirstDepositSlider(value); }}*/}
							{/*	min={0}*/}
							{/*	max={100000}*/}
							{/*/>*/}
							<input
								id="first_deposit"
								type="text"
								name="first_deposit"
								required
								value={firstDeposit}
								onChange={handleFirstDeposit}
							/>
							<span>€</span>
							<div className="error-message">
								{ firstDeposit.toString() === "0" && monthlyDeposit.toString() === "0" ?
								'Bitte geben Sie entweder eine Sparrate oder ein Startkapital größer als 0 € an.' :
								'Bitte geben sie einen Betrag zwischen 0 € und '+ formatNumber(100000)+' € an.'}
							</div>
						</div>
					</div>

					<div className="input-group">
						<label htmlFor="monthly_deposits" style={{ fontSize: headingSize }}>
							Sparrate
						</label>
						<div className="inp">
							<input
								id="monthly_deposits"
								type="text"
								name="monthly_deposits"
								required
								value={monthlyDeposit}
								onChange={handleMonthlyDeposit}
							/>
							<span>€</span>
							<div className="error-message">
								{ firstDeposit.toString() === "0" && monthlyDeposit.toString() === "0" ?
								'Bitte geben Sie entweder eine Sparrate oder ein Startkapital größer als 0 € an.' :
								'Bitte geben Sie einen Betrag zwischen 0 € und '+ formatNumber(20000)+' € an.'}
							</div>
						</div>
					</div>

					<div className="input-group">
						<label htmlFor="interval" style={{ fontSize: headingSize }}>Sparintervall</label>
						<div className="inp">
							<select
								name="interval"
								required
								value={payout}
								onChange={(e) => setPayout(e.target.value)}
							>
								<option value="12">Monatlich</option>
								<option value="4">Vierteljährlich</option>
								<option value="1">Jährlich</option>
							</select>
							<span className="select-arrow"></span>
						</div>
					</div>

					<div className="input-group">
						<label htmlFor="interest_rate" style={{ fontSize: headingSize }}>Rendite pro Jahr in %</label>

							<div className="interest_inp">
								<div className="inp etf">
									<select
										name="etf"
										onChange={handleEtfChange}
										value={etf}
									>
										<option data-quelle={null} value="">Individuell</option>
										<option data-quelle="Renditedreieck DAX" value="8,97">DAX</option>
										<option data-quelle="Renditedreieck MDAX" value="9,40">MDAX</option>
										<option data-quelle="Renditedreieck SDAX" value="8,80">SDAX</option>
										<option data-quelle="Renditedreieck TecDAX" value="9,50">TecDAX</option>
										<option data-quelle="Renditedreieck Stoxx Europe 600" value="3,80">Stoxx Europe 600</option>
										<option data-quelle="Renditedreieck Euro Stoxx 50" value="2,50">Euro Stoxx 50</option>
										<option data-quelle="St. Louis Fed" value="8,60">MSCI All County</option>
										<option data-quelle="St. Louis Fed" value="7,80">MSCI Emerging Market</option>
										<option data-quelle="St. Louis Fed" value="8,65">MSCI World</option>
										<option data-quelle="Renditedreieck NASDAQ-100" value="13,00">NASDAQ-100</option>
										<option data-quelle="Renditedreieck Nikkei 225" value="5,81">NIKKEI 225</option>
										<option data-quelle="Renditedreieck S&P 500" value="10,21">S&P 500</option>
										<option data-quelle="Renditedreieck Gold" value="9,00">Gold</option>
									</select>
									<span className="select-arrow"></span>
								</div>
								<div className="inp interest">
									<input
										id="interest_rate"
										type="text"
										name="interest_rate"
										required
										value={interestRate}
										onChange={handleInterestRate}
									/>
									<span>%</span>
									<div className="error-message">Bitte geben Sie einen Wert über 0,00 % ein.</div>
								</div>
								<div className="tooltip-element">
									<svg className="info_monthly_deposits">
										<use href="#info-svg"></use>
									</svg>
									<ReactTooltip anchorSelect=".info_monthly_deposits" place="top" openEvents={{mouseenter:true,focus:true,click:true}}>
									  Prognostizierte Jahresrendite; sie basiert auf der Annahme, dass die durchschnittliche Performance des Index der vergangenen zwei Jahrzehnte auch in Zukunft Bestand haben wird. Der Zinssatz ist auch zwischen 0,10 - 30,00 % individuell einstellbar.
									</ReactTooltip>
								</div>
							</div>
					</div>

					<div className="input-group">
						<label htmlFor="period" style={{ fontSize: headingSize }}>Laufzeit in Jahren</label>
						<div className="inp">
							<input
								id="period"
								type="text"
								name="period"
								required
								onChange={handleYears}
								value={years}
							/>
							<div className="error-message">Bitte geben Sie eine Dauer zwischen 1 und 50 Jahren an.</div>
						</div>
					</div>


					<button className="form-button" style={{ color: buttonColors[0], backgroundColor: buttonColors[1] }}>Berechnen</button>
				</form>
			</div>
			{res.data && (
			<div className="results-container">
				<h2 className="calculator-form_heading" style={{ color: '#242424', fontFamily: fontFamily2 }}>
					Ergebnis
				</h2>

				<div className="results-container_elm">
					<div className="results-container_item">
						<p style={{ fontSize: headingSize }}>Gesamtvermögen</p>
						<p className="grey-result" style={{ fontSize: paragraphSize }}>{res.end_balance ? formatNumber(res.end_balance) : 0} €</p>
					</div>
					<div className="results-container_item">
						<p style={{ fontSize: headingSize }}>Einzahlungen</p>
						<p style={{ fontSize: paragraphSize }}>{res.gesamteinzahlungen ? formatNumber(res.gesamteinzahlungen) : 0} €</p>
					</div>
					<div className="results-container_item">
						<p style={{ fontSize: headingSize }}>Wertzuwachs</p>
						<p style={{ fontSize: paragraphSize }}>{res.wertzuwachs ? formatNumber(res.wertzuwachs) : 0} €</p>
					</div>
				</div>
				<div className="chart-container" ref={chartContainerRef}>
					<div style={{width: "100%", height: "400px"}}>
						<Bar data={chartData} className={'custom-bar-chart'} options={chartOptions} style={{width:'100%'}} />
					</div>
				</div>

				<Accordion>
					<Accordion.Panel
						header={<span style={{ fontSize: paragraphSize }}>{toggleValue}</span>}
						caretAs={ArrowComponent}
						onSelect={(eventKey, event) => {
							const parent = event.target.parentElement;
							const aria = parent.getAttribute('aria-expanded');
							if(aria === 'false'){
								setToggleValue('Details verbergen');
							} else {
								setToggleValue('Details anzeigen')
							}
						}}
					>
						<table>
							<thead>
								<tr>
									<th style={{ fontSize: headingSize }}>Jahr</th>
									<th style={{ fontSize: headingSize }}>Depotvermögen</th>
									<th style={{ fontSize: headingSize }}>Jährliche Einzahlungen</th>
									<th style={{ fontSize: headingSize }}>Gesamteinzahlungen</th>
									<th style={{ fontSize: headingSize }}>Wertzuwachs im Jahr</th>
									<th style={{ fontSize: headingSize }}>Wertzuwachs gesamt</th>
									<th style={{ fontSize: headingSize }}>Gesamtvermögen</th>
								</tr>
							</thead>
							<tbody>
								{ res.data.map((yearData) => (
									<tr key={yearData.year}>
										<td style={{ fontSize: paragraphSize }}>{yearData.year}</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.starting_amount) } €</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.annual_contribution) } €</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.gesamteinzahlungen) } €</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.yearly_interest) } €</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.wertzuwachs) } €</td>
										<td style={{ fontSize: paragraphSize }}>{ formatNumber(yearData.end_balance) } €</td>
									</tr>
								))}
							</tbody>
							{ quelle && (
								<tfoot><tr><td className={"quelle"} style={{ fontSize: paragraphSize }} colSpan={7}> Quelle: Einmalanlage 2003 bis 2023, {quelle}</td></tr></tfoot>
							)}
						</table>
					</Accordion.Panel>
			  	</Accordion>
			</div>
			)}
			<svg width="0" height="0" className="hidden" xmlns="http://www.w3.org/2000/svg">
				<symbol id="info-svg" viewBox="0 0 8 16">
					<path fillRule="evenodd"
						  d="M4 1a1 1 0 100 2 1 1 0 000-2zM2 2a2 2 0 114 0A2 2 0 012 2zM0 5.5A.5.5.0 01.5 5h5a.5.5.0 01.5.5V15h1.5a.5.5.0 010 1h-7a.5.5.0 010-1H2V6H.5A.5.5.0 010 5.5zM3 6v9h2V6H3z"
						  clipRule="evenodd"></path>
				</symbol>
			</svg>
		</div>
	);
};

export default Calculator;
