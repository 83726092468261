export function formatNumber(number, fractions = 2) {
    // Parse the string as a float
    const parsedNumber = parseFloat(number);
    return parsedNumber.toLocaleString('de-DE', {minimumFractionDigits: fractions});
}

export function calculateETFSavings(
    firstDeposit,
    contribution,
    interval,
    interestRate,
    numberOfYears
) {
    // Initialize variables
    let values = [];
    let totalContributions = firstDeposit;
    let totalInterestEarned = 0;
    let balance = firstDeposit;
    let starting_amount = firstDeposit.toFixed(2);

    // Loop through each year
    for (let year = 1; year <= numberOfYears; year++) {
        let yearlyContribution = 0;
        let interestEarned = 0;
        let r = 0;

        if (interval === 12) {
            yearlyContribution += contribution * 12;
            r = interestRate / 12 / 100;
        } else if (interval === 4) {
            yearlyContribution += contribution * 3;
            r = interestRate / 4 / 100;
        } else if (interval === 1) {
            yearlyContribution += contribution * 1;
            r = interestRate / 100;
        }

        if (interval === 12) {
            let yearMonthlyInterest = 0;
            for (let month = 1; month <= 12; month++) {
                balance += contribution;
                const monthInterest = balance * r;
                yearMonthlyInterest += monthInterest;
            }
            totalInterestEarned += yearMonthlyInterest;
            interestEarned = yearMonthlyInterest;
            balance += interestEarned;
        } else if (interval === 4) {
            let quarterMonthlyInterest = 0;
            for (let times = 1; times < 3; times++) {
                balance += contribution;
                const quarterInterest = balance * r;
                quarterMonthlyInterest += quarterInterest;
            }
            totalInterestEarned += quarterMonthlyInterest;
            interestEarned = quarterMonthlyInterest;
            balance += interestEarned;
        } else if (interval === 1) {
            interestEarned = balance * r;

            totalInterestEarned += interestEarned;
            balance += contribution + interestEarned;
        }

        totalContributions += yearlyContribution;

        // Store the result for the current year
        values.push({
            year,
            starting_amount: starting_amount,
            annual_contribution: yearlyContribution,
            gesamteinzahlungen: totalContributions,
            yearly_interest: interestEarned.toFixed(2),
            wertzuwachs: totalInterestEarned.toFixed(2),
            end_balance: balance.toFixed(2)
        });

        starting_amount = balance.toFixed(2);
    }

    return {
        data: values,
        gesamteinzahlungen: totalContributions,
        wertzuwachs: totalInterestEarned.toFixed(2),
        end_balance: balance.toFixed(2)
    };
}

export function capitalizeFirstLetter(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

// Function to convert English presentations to German
export function convertToGerman(text) {
    // Replace common English to German presentations
    text = text.replace(/ae/g, 'ä');
    text = text.replace(/oe/g, 'ö');
    text = text.replace(/ue/g, 'ü');
    text = text.replace(/Ae/g, 'Ä');
    text = text.replace(/Oe/g, 'Ö');
    text = text.replace(/Ue/g, 'Ü');
    text = text.replace(/ss/g, 'ß');

    return text;
}

export function setValid(element) {
    const parentElement = element.parentElement; // Get the parent element
    parentElement.classList.remove('invalid');
    if (element) {
        element.classList.remove('invalid');
    }
}

export function setInvalid(element) {
    const parentElement = element.parentElement; // Get the parent element
    element.classList.add('invalid'); // Adding the 'invalid' class
    parentElement.classList.add('invalid');
}

export function validateAndSet(input) {
    const valid = validateCheck(input);
    if (valid) {
        setValid(input);
    } else {
        setInvalid(input);
    }
}

export function validateCheck(input) {
    let numeric = input.value.replace(/\./g, '');
    numeric = input.value.replace(/,/g, '.');
    switch (input.name) {
        case 'first_deposit':
            const monthlyDepositInput = document.getElementById('monthly_deposits');
            if (numeric < 0 || numeric > 100000 || (numeric < 1 && monthlyDepositInput.value < 1)) {
                return false;
            }
            break;
        case 'monthly_deposits':
            const firstDepositInput = document.getElementById('first_deposit');
            if (numeric < 0 || numeric > 20000 || (numeric < 1 && firstDepositInput.value < 1)) {
                return false;
            }
            break;
        case 'interest_rate':
            if (numeric < 0) {
                return false;
            }
            break;
        case 'period':
            if (numeric < 1 || numeric > 50) {
                return false;
            }
            break;
        // Add cases for other input fields as needed
        default:
            break;
    }
    return true;
}