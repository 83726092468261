import './App.css';
// import 'rsuite/Slider/styles/index.css';
import 'rsuite/Accordion/styles/index.css';
import Calculator from './calculator/Calculator';

function App() {
  return (
    <div>
      <Calculator />
    </div>
  );
}

export default App;
